import request from '@/utils/request2'

// 1.	获取工作流表单基本信息接口
export function getWFFormBaseInfo (data) {
  return request({
    url: '/api/workflow/getWFFormBaseInfo',
    method: 'get',
    params: data
  })
}

// 2.	获取工作流单据主表表单字段信息接口
export function getWFFormFieldDetail (data) {
  return request({
    url: '/api/workflow/getWFFormFieldDetail',
    method: 'post',
    data
  })
}

// 3.	获取工作流单据主表记录内容接口
export function getWFFormRecordData (data) {
  return request({
    url: '/api/workflow/getWFFormRecordData',
    method: 'post',
    data
  })
}

// 4.	获取工作流单据主表新增默认值接口
export function getWFFormNewDefaultValue (data) {
  return request({
    url: '/api/workflow/getWFFormNewDefaultValue',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
    data
  })
}

// 5.	工作流表单数据带出接口
export function getWFFormFieldExitValue (data) {
  return request({
    url: '/api/workflow/getWFFormFieldExitValue',
    method: 'post',
    data
  })
}

// 6.	工作流表单数据退出提醒接口
export function getWFFormFieldExitInfo (data) {
  return request({
    url: '/api/workflow/getWFFormFieldExitInfo',
    method: 'post',
    data
  })
}

// 9.	获取某个动作策略前的过程列表接口
export function getBeforeActionList (data) {
  return request({
    url: '/api/workflow/getBeforeActionList',
    method: 'get',
    params: data
  })
}

// 10.	执行某个动作策略前过程接口
export function processBeforeAction (data) {
  return request({
    url: '/api/workflow/processBeforeAction',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
    data
  })
}

// 11.	保存工作流单据主表记录内容接口
export function saveWFRecord (params) { 
  return request({
    url: '/api/workflow/saveWFRecord',
    method: 'post',
    data: params
  })
}

// 12.	提交工作流单据接口
export function postWorkFlow (data) {
  return request({
    url: '/api/workflow/postWorkFlow',
    method: 'post',
    data
  })
}

// 12.	提交工作流单据接口
export function postWorkFlow_Test (data) {
  return request({
    url: '/api/workflow/postWorkFlow_Test',
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
    method: 'post',
    data
  })
}

// 13.	否决工作流单据接口
export function returnWorkFlow (data) {
  return request({
    url: '/api/workflow/returnWorkFlow',
    method: 'post',
    data
  })
}

// 14.	删除工作流单据接口
export function deleteWorkFlow (data) {
  return request({
    url: '/api/workflow/deleteWorkFlow',
    method: 'get',
    params: data
  })
}

// 15.	获取单据附件列表接口
export function getWFAttFileList (data) {
  return request({
    url: '/api/workflow/getWFAttFileList',
    method: 'get',
    params: data
  })
}

// 21.	获取审批流程明细信息接口
export function getWorkFlowDetail (data) {
  data.flag = data.flag?data.flag:0
  return request({
    url: '/api/workflow/getWorkFlowDetail',
    method: 'get',
    params: data
  })
}
// 查看单据历史审批记录接口
export function getHisWorkFlowDetail (data) {
  data.flag = data.flag?data.flag:0
  return request({
    url: '/api/workflow/getHisWorkFlowDetail',
    method: 'get',
    params: data,
    loading: 0
  })
}

// 22.	获取单个流程节点的会签明细接口
export function getMcDetail (data) {
  return request({
    url: '/api/workflow/getMcDetail',
    method: 'get',
    params: data,
    loading: 0
  })
}

// 23.	对比导入Excel标题格式接口
export function compareExcelTitle (params) {
  const { formautoid, userid, titlelist } = params
  return request({
    url: '/api/workflow/compareExcelTitle',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
    data: { formautoid, userid, titlelist }
  })
}

// 导入 >>> 对比导入Excel标题格式接口
export function getExportFieldList (params) {
  const { formautoid, userid, checkfieldpri, langid } = params
  return request({
    url: '/api/workflow/getExportFieldList',
    method: 'get',
    params: { formautoid, userid, checkfieldpri, langid }
  })
}

// 24.	获取指定表单对应的报表url和报表rpid
export function getWorkFlowReportInfo (params) {
  return request({
    url: '/api/workflow/getWorkFlowReportInfo',
    method: 'get',
    params
  })
}

// 26.	获取某个单据的审核状态
export function getListCheckInfo (params) {
  const { formautoid, mautoid, userid } = params
  return request({
    url: '/api/workflow/getListCheckInfo',
    method: 'get',
    params: { formautoid, mautoid, userid }
  })
}

// 27. 获取详细的子表信息
export function getDetailGridCopyData (data) {
  return request({
    url: '/api/workflow/getDetailGridCopyData',
    method: 'post',
    data: data
  })
}

// 28. 点击查看已完结和被撤销单据后调用的接
export function updateCheckListStatus (params) {
  return request({
    url: '/api/workbench/updateCheckListStatus',
    method: 'get',
    params: params
  })
}

// 29. 根据工号获取工号empcode
export function getEmpId (params) {
  const { empcode } = params
  return request({
    url: '/api/curd/getEmpId',
    method: 'get',
    params: { empcode }
  })
}

// 30. 工作流表单子表全局保存前提示接口
export function beforeCheckDetailData (data) {
  let newdata = JSON.parse(JSON.stringify(data))
  return request({
    url: '/api/workflow/beforeCheckDetailData',
    method: 'post',
    data: newdata
  })
}

export function changeCheckUser (data) {
  return request({
    url: '/api/workflow/changeCheckUser',
    method: 'get',
    params: data
  })
}

// 检查是不是管理员checkIsFormAdmin

export function checkIsFormAdmin (data) {
  return request({
    url: '/api/workflow/checkIsFormAdmin',
    method: 'get',
    params: data
  })
}

// 检查审批的权限
export function canChangeCheck (data) {
  return request({
    url: '/api/workflow/canChangeCheck',
    method: 'get',
    params: data
  })
}

// 保存主表时，触发附件的保存
export function batchUpLoadFile (data) {
  return request({
    url: '/api/workflow/batchUpLoadFile',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' }
  })
}

// 审批流单独触发保存后动作
export function processAfterAction (data) {
  return request({
    url: '/api/workflow/processAfterAction',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
    data
  })
}


export function getSubordinateInfo (data) {
  return request({
    url: '/api/workbench/getSubordinateInfo',
    method: 'get',
    params: data
  })
}

// 获取审批记录表格的字段列信息接口
export function getWorkFlowDetailFieldList (data) {
  return request({
    url: '/api/workflow/getWorkFlowDetailFieldList',
    method: 'get',
    params: data
  })
}

//获取历史审批记录表格的字段列信息接口
export function getHisWorkFlowDetailFieldList (data) {
  return request({
    url: '/api/workflow/getHisWorkFlowDetailFieldList',
    method: 'get',
    params: data
  })
}

// 获取绩效评检进度信息接口
export function getJxPjJd (data) {
  return request({
    url: '/api/workbench/getJxPjJd',
    method: 'get',
    params: data
  })
}

// 绩效阶段汇总
export function getJxJdHz (data) {
  return request({
    url: '/api/workbench/getJxJdHz',
    method: 'get',
    params: data
  })
}

// 获取当前流程节点对应链接信息接口：

export function getCurNodeLinkList (data) {
  return request({
    url: '/api/workflow/getCurNodeLinkList',
    method: 'get',
    params: data
  })
}

export function clickLinkUrl (data) {
  return request({
    url: '/api/workflow/clickLinkUrl',
    method: 'get',
    params: data
  })
}
// 根据工号列表 获取工号信息
export function getEmpInfoByEmpCodeList (data) {
  return request({
    url: '/api/baseform/getEmpInfoByEmpCodeList',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
    data
  })
}

// 获取抄送人信息列表
export function getHisCsDataList (data) {
  return request({
    url: '/api/workflow/getHisCsDataList',
    method: 'get',
    params: data
  })
}

// 回退流程到指定节点的接口
export function backToFlowFixNode (data) {
  return request({
    url: '/api/workflow/backToFlowFixNode',
    method: 'get',
    params: data
  })
}

export function upLoadUserSignPic (data) {
  return request({
    url: '/api/file/upLoadUserSignPic',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
    data
  })
}

// 获取流程中心动态数据接口
export function getDynamicForCheckList (data) {
  return request({
    url: '/api/workbench/getDynamicForCheckList',
    method: 'get',
    params: data
  })
}

// 获取审批流上笔或下笔单据信息接口
export function getPreNextListInfo (data) {
  return request({
    url: '/api/workflow/getPreNextListInfo',
    method: 'get',
    params: data
  })
}

//获取用户最后一次签名图片地址
export function getUserLastSignPicUrl (data) {
  return request({
    url: '/api/workflow/getUserLastSignPicUrl',
    method: 'get',
    params: data
  })
}

// 获取下拉框值列表（针对多数据源和webapi）
export function getListSource (data) {
  return request({
    url: '/api/baseform/getListSource',
    method: 'post',
    data
  })
}

// 通用 >>> 通用表单 根据工号或姓名获取符合条件的人员信息
export function getEmpInfo (data) {
  return request({
    url: '/api/curd/getEmpInfo',
    method: 'get',
    params: data
  })
}

// 通用表单 >>> 下拉框 >>> 自定义查询
export function GetCustomQueryInfo (data) {
  return request({
    url: '/api/query/getCustomQueryInfo',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' }
  })
}

// 获取员工弹窗对应模块号
export function getQueryEmpModuleNo(data) {
  const { moduleno, username, iswf, fieldname } = data
  return request({
    url: '/api/query/getQueryEmpModuleNo',
    method: 'get',
    params: { moduleno, username, iswf, fieldname }
  })
}

// 获取评论记录列表接口
export function getFlowCommentRecord (data) {
  return request({
    url: '/api/workflow/getFlowCommentRecord',
    method: 'get',
    params: data
  })
}

//  新增发表评论接口
export function postFlowComment (data) {
  return request({
    url: '/api/workflow/postFlowComment',
    method: 'post',
    data
  })
}

// 2 删除单据接口（支持批量）
export function deleteWorkFlowList(params) {
 
  const {
    guid,
    formautoid,
    mkeyname,
    mtablename,
    mviewname,
    isall,
    autoidlist,
    moduleqyfilter,
    specfilter,
    querysql,
    username,
    optionfilter
  } = params
  return request({
    url: '/api/m9913/deleteWorkFlowList',
    method: 'post',
    data: {
      guid,
      formautoid,
      mkeyname,
      mtablename,
      mviewname,
      isall,
      autoidlist,
      moduleqyfilter,
      specfilter,
      querysql,
      username,
      optionfilter
    }
  })
}

// 12.	审批推送接口
export function pushwxmsg(data) {
  return request({
    url: '/api/m9913/pushwxmsg',
    method: 'post',
    data: data
  })
}

// 5 撤销单据接口（支持批量）
export function cancelWorkFlowList(params) {
  const {
    guid,
    formautoid,
    mkeyname,
    mtablename,
    mviewname,
    isall,
    autoidlist,
    moduleqyfilter,
    specfilter,
    querysql,
    username,
    checkmemo,
    optionfilter
  } = params
  return request({
    url: '/api/m9913/cancelWorkFlowList',
    method: 'post',
    data: {
      guid,
      formautoid,
      mkeyname,
      mtablename,
      mviewname,
      isall,
      autoidlist,
      moduleqyfilter,
      specfilter,
      querysql,
      username,
      checkmemo,
      optionfilter
    }
  })
}
