<template>
  <!-- 审批流查询，列表界面 -->
  <div class="wfClass">
    <van-nav-bar :title="title" left-arrow @click-left="onClickLeft">
    </van-nav-bar>
    <div class="search">
      <van-search v-model="value" placeholder="请输入搜索关键词" @input="onSearch" />
      <van-checkbox style="font-size: 14px;margin-right: 7px;" v-show="showcheck" v-model="checked" icon-size="14px" @click="checkAll(checked)">全选</van-checkbox>
      <div class="gettype" @click="showPicker = true" v-if="type == '2'">
        <span>{{ sstype }}<van-icon name="arrow-down" /></span>
      </div>
      <van-icon name="apps-o" v-if="type == '1' || type == '2' || type == '5'" @click="showcheck = !showcheck" />
      <van-icon name="calendar-o" @click="onShowCalendar" />
    </div>

    <div class="applyCollapse" :class="{ showcheck: showcheck,homeAppClassIs1:homeAppClass }">
      <van-cell v-for="(value, key) in afworkflowlist" :key="key" @click="jump(value)" style="text-align: center"
        :title="value.applistmemo">
        <div slot="icon">
          <div class="beforcell">
            <van-checkbox v-if="showcheck && !(value.ischeck || value.return)" v-model="value.checked" shape="square" />
            <div class="ico"></div>
          </div>
        </div>
        <div slot="default">
          <span 
            :cx_flag="value.cx_flag"
            :class="{ st: true, ischeck: value.ischeck,isdtj:type == 5 }"
            :style="value.cx_flag == 0?'color: #eb905b;background: #f5efc1;':value.cx_flag == 1?'color: #666;background: #ccc;':value.cx_flag == 2?'color: #6042d9;background: #c9c1f5;':value.cx_flag == 3?'color: #00b65b;background: #c1f5ea;':'color: #eb905b;background: #f5efc1;'"
          >
            {{
              value.cx_flag == 0?'审批中':value.cx_flag == 1?'已撤销':value.cx_flag == 2?'已否决':value.cx_flag == 3?'已通过':value.cx_flag == 4?'待提交':'请审批'
            }}
          </span>
        </div>
        <template slot="label">
          {{ value.crdate }}
        </template>
      </van-cell>
    </div>
    <div v-show="showcheck" class="footer_btn">
      <span style="font-size: 18px;color: red;font-weight: bold;">{{ tjTxt }}</span>
      <div>
        <van-button style="width: 80px;" size="small" type="info" :loading="loading" @click="_pushwxmsg" v-if="type == '2'">催单</van-button>
        <van-button style="width: 80px;" size="small" type="danger" :loading="loading" @click="settype(2)" v-if="type == '1'">否决</van-button>
        <van-button style="width: 80px;" size="small" type="info" :loading="loading" @click="settype(1)" v-if="type == '1'">{{ use_new_workflow?'同意':'提交' }}</van-button>
        <van-button style="width: 80px;" size="small" type="info" :loading="loading" @click="_postWorkFlowList" v-if="type == '5'">提交</van-button>
        <van-button style="width: 80px;" size="small" type="danger" :loading="loading" @click="_deleteWorkFlowList" v-if="type == '5'">删除</van-button>
      </div>
      
    </div>
    <van-popup v-model="showPicker" position="bottom">
      <van-picker show-toolbar :columns="columns" @confirm="onConfirm" @cancel="showPicker = false" />
    </van-popup>
    <van-dialog v-model="showCalendar" title="单据查询" show-cancel-button :beforeClose="beforeClose2">
      <div class="documentDjcxBox">
       
        <van-field
          fieldname="kaiShiRiQi"
          v-model="kaiShiRiQi"
          readonly
          clickable
          label="开始日期"
          @click="settingDatetime(1)"
        >
          <template #right-icon>
            <div style="display: flex;align-items: center;">
              <van-icon v-if="kaiShiRiQi" @click.stop="()=>{kaiShiRiQi = ''}" style="margin-right: 5px;" name="close" />
              <svg-icon icon-class="calendarIcon"></svg-icon>
            </div>
          </template>
        </van-field>
        <van-field
          fieldname="jieShuRiQi"
          v-model="jieShuRiQi"
          readonly
          clickable
          label="结束日期"
          @click="settingDatetime(2)"
        >
          <template #right-icon>
            <div style="display: flex;align-items: center;">
              <van-icon v-if="jieShuRiQi" @click.stop="()=>{jieShuRiQi = ''}" style="margin-right: 5px;" name="close" />
              <svg-icon icon-class="calendarIcon"></svg-icon>
            </div>
          </template>
        </van-field>
        <van-field
          fieldname="gongHaoHuoXingMing"
          v-model="gongHaoHuoXingMing"
          label="工号或姓名"
          placeholder="请输入"
        >
          
        </van-field>
      </div>
    </van-dialog>
    <van-dialog v-model="showYijian" title="请填写意见" show-cancel-button :beforeClose="beforeClose">
      <van-field
        v-model="yiJian"
        rows="5"
        label="请输入"
        type="textarea"
        placeholder="请输入内容"
        required

      />
    </van-dialog>
    <van-popup v-model="showDatetimePicker" position="bottom">
      <van-datetime-picker
        title="请选择日期"
        v-model="currentDatetime"
        type="date"
        @confirm="onDatetimeConfirm"
        @cancel="showDatetimePicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant'
import {
  getActionWorkFlowOfOneClass,
  getActionWorkFlow,
  getOneClassOfMyWf,
  getOneClassOfMyCheckWf,
  getReturnWorkFlowOneClass,
  pushwxmsg,
  getNotPostList,
  deleteWorkFlowList,
  postWorkFlowList,
  postWorkFlowListCheck,
  disPostWorkFlowList,
  getCsWorkFlowOfOneClass,
  updateCheckListStatus
} from '@/api/wx.js'
import {
  getWFFormBaseInfo
} from "@api/workFlow";
import { parseTime, createGguid } from '@/utils'
export default {
  data() {
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    const type = this.$route.query.type
    const formautoid = this.$route.query.formautoid
    const appname = this.$route.query.appname
    const appiconid = this.$route.query.appiconid
    return {
      userInfo,
      value: '',
      type,
      formautoid,
      appname,
      appiconid,
      workflowlist: [],
      ini2: [],
      bd: '',
      ed: '',
      V3url: './',
      sstype: '所有单据',
      columns: ['所有单据', '审核完结', '审核中'],
      showPicker: false,
      showcheck: false,
      checked: false,
      showCalendar: false,
      loading: false,
      minDate: new Date(new Date().getFullYear() - 20, 0, 1),
      maxDate: new Date(new Date().getFullYear() + 20, 0, 31),
      showYijian: false,
      yiJian:"",
      selectType:0,
      errorData:[],
      homeAppClass:false,
      kaiShiRiQi:"",
      jieShuRiQi:"",
      gongHaoHuoXingMing:"",
      showDatetimePicker:false,
      currentDatetime:"",
      timeSelectType:0,
      tjTxt:""

    }
  },
  computed: {
    title() {
      const arr = [
        '',
        localStorage.getItem('dwsp_caption'),
        localStorage.getItem('wfqd_caption'),
        localStorage.getItem('wysp_caption'),
        localStorage.getItem('bcxd_caption'),
        localStorage.getItem('wtjd_caption'),
        localStorage.getItem('cs_caption'),
      ]
      return arr[Number(this.type)] + (this.appname ? `(${this.appname})` : '')
    },
    use_new_workflow () {
      if(process.env.NODE_ENV !== 'production'){
        return true;
      }else{
        if(window.use_new_workflow){
          return true;
        }else{
          return false;
        }
      }
    },
    afworkflowlist() {
      let arr = this.workflowlist
      switch (this.sstype) {
        case '所有单据':
          arr = this.workflowlist.filter(e => {
            return true
          })
          break
        case '审核完结':
          arr = this.workflowlist.filter(e => {
            return e.ischeck || e.return
          })
          break
        case '审核中':
          arr = this.workflowlist.filter(e => {
            return !(e.ischeck || e.return)
          })
          break
      }
      return arr
    }
  },
  created() {
    // total_score_title
    localStorage.setItem("RouterQueryObj",JSON.stringify(this.$route.query))
    this.type = this.$route.query.type
    this.formautoid = this.$route.query.formautoid
    this.appname = this.$route.query.appname
    this.appiconid = this.$route.query.appiconid
    this.initData()
    let getOpenApp = sessionStorage.getItem('isOpenApp')
    var u = navigator.userAgent;
    var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if(getOpenApp == 1 && isiOS){
      this.homeAppClass = true
    }
  },
  mounted() {
    setTimeout(() => {
      document.getElementsByClassName('van-tabbar-item')[0].style.color =
        '#646566'
      document.getElementsByClassName('van-tabbar-item')[1].style.color =
        '#1989fa'
    }, 200)
  },
  methods: {
    toJiLa () {
      let n = this.afworkflowlist;
      let total_score_title = this.$route.query.total_score_title;
      let show_total_score = this.$route.query.show_total_score;
      if(show_total_score == 1 && total_score_title){
        let num = 0;
        n.forEach(item=>{
          if(item.totalscore && !isNaN(item.totalscore)){
            if(item.checked){
              num = num + item.totalscore;
            }
          }
        })
        num = num.toFixed(2);
        if(isNaN(num)){
          this.tjTxt = total_score_title.replace("{totalscore}","0.00");
        }else{
          const txt = total_score_title.replace("{totalscore}",num)
          this.tjTxt = txt;
        }
      }
    },
    settingDatetime (type) {
      this.timeSelectType = type;
      if(type == 1){
        this.currentDatetime = this.kaiShiRiQi?new Date(this.kaiShiRiQi):new Date()
      }else{
        this.currentDatetime = this.jieShuRiQi?new Date(this.jieShuRiQi):new Date()
      }
      this.showDatetimePicker = true;
    },
    onDatetimeConfirm (time) {
      const vv = parseTime(time, '{y}-{m}-{d}');
      if(this.timeSelectType == 1){
        this.kaiShiRiQi = vv;
      }else{
        this.jieShuRiQi = vv;
      }
      this.showDatetimePicker = false;
    },
    onShowCalendar () {
      this.kaiShiRiQi = parseTime(new Date(), '{y}-{m}-{d}');
      this.jieShuRiQi = parseTime(new Date(), '{y}-{m}-{d}');
      this.gongHaoHuoXingMing = "";
      this.showCalendar = true;
      
    },
    beforeClose2(action, done) {
      if (action === "confirm") {
        this.bd = this.kaiShiRiQi
        this.ed = this.jieShuRiQi
        this.initData()
        this.showCalendar = false
        done();
      }else{
        this.showCalendar = false
        done();
      }
    },
    async initData() {
      switch (this.type) {
        case '1':
          if (this.formautoid == '0') {
            // 待我审批
            await getActionWorkFlow({
              empcode: this.userInfo.empcode,
              langid: localStorage.getItem('langid'),
              bd: this.bd,
              ed: this.ed,
              query_empcode:this.gongHaoHuoXingMing
            }).then(res => {
              this.bulid(res.data)
            })
          } else {
            // 待我审批(一类)
            await getActionWorkFlowOfOneClass({
              empcode: this.userInfo.empcode,
              formautoid: this.formautoid,
              langid: localStorage.getItem('langid'),
              bd: this.bd,
              ed: this.ed,
              query_empcode:this.gongHaoHuoXingMing
            }).then(res => {
              this.bulid(res.data)
            })
          }

          break
        case '2':
          // 我发起的
          await getOneClassOfMyWf({
            empcode: this.userInfo.empcode,
            formautoid: this.formautoid,
            langid: localStorage.getItem('langid'),
            bd: this.bd,
            ed: this.ed,
            query_empcode:this.gongHaoHuoXingMing
          }).then(res => {
            this.bulid(res.data)
          })
          break
        case '3':
          // 已通过
          await getOneClassOfMyCheckWf({
            empcode: this.userInfo.empcode,
            formautoid: this.formautoid,
            langid: localStorage.getItem('langid'),
            bd: this.bd,
            ed: this.ed,
            query_empcode:this.gongHaoHuoXingMing
          }).then(res => {
            this.bulid(res.data)
          })
          break
        case '4':
          // 被撤销的
          await getReturnWorkFlowOneClass({
            empcode: this.userInfo.empcode,
            formautoid: this.formautoid,
            langid: localStorage.getItem('langid'),
            bd: this.bd,
            ed: this.ed,
            query_empcode:this.gongHaoHuoXingMing
          }).then(res => {
            this.bulid(res.data)
          })
          break
        case '5':
          // 未提交的
          await getNotPostList({
            empcode: this.userInfo.empcode,
            formautoid: this.formautoid,
            langid: localStorage.getItem('langid'),
            bd: this.bd,
            ed: this.ed,
            query_empcode:this.gongHaoHuoXingMing
          }).then(res => {
            this.bulid(res.data)
          })
          break
        case '6':
          await getCsWorkFlowOfOneClass({
            empcode: this.userInfo.empcode,
            formautoid: this.formautoid,
            langid: localStorage.getItem('langid'),
            bd: this.bd,
            ed: this.ed,
            query_empcode:this.gongHaoHuoXingMing
          }).then(res => {
            this.bulid(res.data)
          })
          break  
        default:
          break
      }
    },
    // 构造分组列表
    bulid(data) {
      this.workflowlist = []
      data.forEach((e, i) => {
        e.appiconid = this.appiconid
        if (this.formautoid != '0') {
          e.appname = this.appname
          e.formautoid = this.formautoid
          e.check = false
        }
      })
      this.ini2 = JSON.parse(JSON.stringify(data))
      this.workflowlist = data
    },
    onClickLeft() {
      if (this.type == '1' && this.formautoid == '0') {
        this.$router.push({
          path: '/apply'
        })
      } else {
        this.$router.push({
          path: '/wfClass',
          query: { type: this.type }
        })
      }
    },
    onClickRight() {},
    onSearch(val) {
      if (val) {
        this.workflowlist = this.ini2.filter(e => {
          return e.applistmemo.indexOf(val) > -1
        })
      } else {
        this.workflowlist = JSON.parse(JSON.stringify(this.ini2))
      }
    },
    onCancel() {
      this.value = ''
      this.workflowlist = JSON.parse(JSON.stringify(this.ini2))
    },
    jump(v) {
      
      if(this.showcheck){
        v.checked = !v.checked;
        this.toJiLa();
        this.$forceUpdate();
        return
      }
      if(window.use_new_wf_form == 1 || process.env.NODE_ENV !== 'production'){
        if(this.type == '6'){
          const queryObj = {
            saveormodify:"readonly",
            mautoid:v.mautoid,
            formautoid:v.formautoid,
            formname:v.appname,
            nodeid:v.nodeid,
            documentType:this.type,
            time: new Date().getTime()
          }
          this.$router.push({
            path: '/workFlow',
            query: queryObj
          })
          
        }else{
          var url = ''
          if (this.type == '5') {
            const queryObj = {
              saveormodify:"modify",
              mautoid:v.mautoid,
              formautoid:v.formautoid,
              formname:v.appname,
              nodeid:1,
              documentType:this.type,
              time: new Date().getTime(),
              
            }
            this.$router.push({
              path: '/workFlow',
              query: queryObj
            })
          } else if(this.type == '4'){
            const queryObj = {
              saveormodify:"modify",
              mautoid:v.mautoid,
              formautoid:v.formautoid,
              formname:v.appname,
              nodeid:v.nodeid,
              documentType:this.type,
              time: new Date().getTime(),
            }
            this.$router.push({
              path: '/workFlow',
              query: queryObj
            })
          } else if(this.type == '2'){
            const queryObj = {
              saveormodify:"readonly",
              mautoid:v.mautoid,
              formautoid:v.formautoid,
              formname:v.appname,
              nodeid:1,
              documentType:this.type,
              time: new Date().getTime(),
              m_ischeck:v.m_ischeck,
              queryCxFlag:v.cx_flag
            }
            this.$router.push({
              path: '/workFlow',
              query: queryObj
            })
          } else if(this.type == '3'){
            const queryObj = {
              saveormodify:"readonly",
              mautoid:v.mautoid,
              formautoid:v.formautoid,
              formname:v.appname,
              nodeid:v.nodeid,
              documentType:this.type,
              time: new Date().getTime(),
              m_ischeck:v.m_ischeck,
            }
            this.$router.push({
              path: '/workFlow',
              query: queryObj
            })
          }else {
            const queryObj = {
              saveormodify:"check",
              mautoid:v.mautoid,
              formautoid:v.formautoid,
              formname:v.appname,
              nodeid:v.nodeid,
              documentType:this.type,
              time: new Date().getTime(),
            }
            this.$router.push({
              path: '/workFlow',
              query: queryObj
            })
          }
        }
      }else{
        if(this.type == '6'){
          updateCheckListStatus({
            checkuserid: this.userInfo.userid,
            formautoid: v.formautoid,
            autoid: v.mautoid,
            flag: 3
          }).then(res=>{
            setLocVal('wfqdinfo', v.autoid)
            setLocVal('wfqdlist', v.formautoid)
            var url = ''
            if (this.type == '5') {
              setLocVal(
                'hyrptparas',
                this.appname + '|' + '' + url + '|' + '' + v.autoid
              )
              url = 'shengpiinfoOnly.html'
            } else {
              setLocVal('sptype', this.type)
              setLocVal(
                'wfqdinfomsg',
                v.appiconid + '|' + v.applistmemo + '|' + v.crdate
              )
              setLocVal('candroplist', v.candroplist)
              url = 'wfqdinfo.html'
            }
            let href = this.V3url + '/weixinlocal/queryrpt/' + url
            this.$router.push({
              path: '/jump',
              query: { url: href, time: new Date().getTime() }
            })
          })
        }else{
          setLocVal('wfqdinfo', v.autoid)
          setLocVal('wfqdlist', v.formautoid)
          var url = ''
          if (this.type == '5') {
            setLocVal(
              'hyrptparas',
              this.appname + '|' + '' + url + '|' + '' + v.autoid
            )
            url = 'shengpiinfoOnly.html'
          } else {
            setLocVal('sptype', this.type)
            setLocVal(
              'wfqdinfomsg',
              v.appiconid + '|' + v.applistmemo + '|' + v.crdate
            )
            setLocVal('candroplist', v.candroplist)
            url = 'wfqdinfo.html'
          }
          let href = this.V3url + '/weixinlocal/queryrpt/' + url
          this.$router.push({
            path: '/jump',
            query: { url: href, time: new Date().getTime() }
          })
        }
      }
    },
    async fromProcessEvent (formautoid, checkmemo, autoid, editstate) {
      if (editstate == 1) {
        let resbi = await getWFFormBaseInfo({
          formautoid: formautoid,
          mautoid: 0,
          nodeid: 0,
          userid: this.userInfo.userid,
          langid: localStorage.getItem('langid') || 0,
          mtag: 0
        })
        const moduleConfig = resbi.forminfo[0]
        const params = {
          guid: createGguid(),
          formautoid: formautoid,
          mkeyname: moduleConfig.mkeyname,
          mtablename: moduleConfig.mtablename,
          mviewname: moduleConfig.mviewname,
          isall: 0,
          autoidlist: autoid,
          moduleqyfilter: '',
          specfilter: '1=1',
          querysql: '1=1',
          username: this.userInfo.username,
          checkmemo: checkmemo,
          queryindex: 0,
          optionfilter: [],
          flag: 1
        }
        const res = await postWorkFlowListCheck(params)
        if (res.iserror == 0)
        {
          if (res.data.length > 0)
          {
            this.errorData.push({ res: res.data[0].res })
          }
        }
      } else
      {
        let resbi = await getWFFormBaseInfo({
          formautoid: formautoid,
          mautoid: 0,
          nodeid: 0,
          userid: this.userInfo.userid,
          langid: localStorage.getItem('langid') || 0,
          mtag: 0
        })
        const moduleConfig = resbi.forminfo[0]
        const params = {
          guid: createGguid(),
          formautoid: formautoid,
          mkeyname: moduleConfig.mkeyname,
          mtablename: moduleConfig.mtablename,
          mviewname: moduleConfig.mviewname,
          isall: 0,
          autoidlist: autoid,
          moduleqyfilter: '',
          specfilter: '1=1',
          querysql: '1=1',
          username: this.userInfo.username,
          checkmemo: checkmemo,
          queryindex: 0,
          optionfilter: [],
          flag: 1
        }
        const res = await disPostWorkFlowList(params)
        if (res.iserror == 0)
        {
          if (res.data.length > 0)
          {
            this.errorData.push({ res: res.data[0].res })
          }
        }
      }
    },
    async beforeClose (action, done) {
      if (action === "confirm") {
        if(!this.yiJian){
          Toast('意见必填！')
          done(false);
          return;
        }else{
          const arr = this.workflowlist.filter(e => {
            return e.checked
          })
          console.log(arr,'arrarr')
          if(this.selectType == 1){
            for (let obj of arr){
              await this.fromProcessEvent(
                obj.formautoid,
                this.yiJian,
                obj.mautoid,
                1
              )
            }
          }else{
            for (let obj of arr){
              await this.fromProcessEvent(
                obj.formautoid,
                this.yiJian,
                obj.mautoid,
                2
              )
            }
          }
          done();
          this.initData()
          this.showcheck = false;
          if (this.errorData.length > 0){
            Dialog.alert({
              message: JSON.stringify(this.errorData)
            }).then(() => {})
          }
        }

      } else {
        done();
      }
    },
    
    settype(v) {
      if(this.use_new_workflow){
        this.selectType = v;
        const arr = this.workflowlist.filter(e => {
          return e.checked
        })
        if(arr.length > 0){
          if(v == 1){
            this.yiJian = '同意'
            this.showYijian = true;
          }else{
            this.yiJian = ''
            this.showYijian = true;
          }
        }else{
          Toast('请选择记录！')
        }
      }else{
        setLocVal('settype', v)
        setLocVal('can_appbg_batch_check', '0')
        if (v.can_appbg_batch_check) {
          setLocVal('can_appbg_batch_check', '1') // 是否后台批量处理
        }
        let list = this.afworkflowlist.filter(e => {
          return e.checked && !(e.ischeck || e.return)
        })
        if (list.length == 0) {
          Toast('请选择记录！')
          return
        }
        this.loading = true
        setLocVal(
          'hywfautoidlist',
          list
            .map(e => {
              return e.autoid
            })
            .join('|')
        )
        setLocVal(
          'hywfformautoidlist',
          list
            .map(e => {
              return e.formautoid
            })
            .join('|')
        )
        setLocVal(
          'hywfapplistmemolist',
          list
            .map(e => {
              return e.applistmemo
            })
            .join('|')
        )
        var url = 'settypebatch.html'
        let href = this.V3url + '/weixinlocal/queryrpt/' + url
        this.$router.push({
          path: '/jump',
          query: { url: href, time: new Date().getTime() }
        })
      }
    },
    onConfirm(value) {
      this.sstype = value
      this.showPicker = false
    },
    
    checkAll(checked) {
      this.workflowlist.forEach(e => {
        e.checked = checked
      })
      this.toJiLa();
      this.$forceUpdate()
    },
    _pushwxmsg() {
      const guid = createGguid()
      let data = this.afworkflowlist
        .filter(e => {
          return e.checked && !(e.ischeck || e.return)
        })
        .map(e => {
          return { autoid: e.mautoid }
        })
      if (data.length == 0) {
        Toast('请选择记录！')
        return
      }
      this.loading = true
      pushwxmsg({
        guid,
        data,
        formautoid: this.formautoid,
        username: this.userInfo.username,
        formautoid: this.formautoid,
        mkeyfield: this.$route.query.mkeyname,
        mtablename: this.$route.query.mtablename,
        mviewname: this.$route.query.mviewname
      })
        .then(res => {
          Toast('催单成功')
        })
        .finally(() => {
          this.loading = false
        })
    },
    _postWorkFlowList() {
      let autoidlist = this.afworkflowlist
        .filter(e => {
          return e.checked
        })
        .map(e => {
          return e[this.$route.query.mkeyname]
        })
      if (autoidlist.length == 0) {
        Toast('请选择记录！')
        return
      }

      Dialog.confirm({
        // title: '标题',
        message: '确认提交吗？'
      }).then(() => {
        this.loading = true
        const guid = createGguid()

        postWorkFlowList({
          guid,
          autoidlist: autoidlist.join(','),
          formautoid: this.formautoid,
          username: this.userInfo.username,
          formautoid: this.formautoid,
          mkeyname: this.$route.query.mkeyname,
          mtablename: this.$route.query.mtablename,
          mviewname: this.$route.query.mviewname
        })
          .then(res => {
            Toast('提交成功')
            this.initData()
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
    _deleteWorkFlowList() {
      let autoidlist = this.afworkflowlist
        .filter(e => {
          return e.checked
        })
        .map(e => {
          return e[this.$route.query.mkeyname]
        })
      if (autoidlist.length == 0) {
        Toast('请选择记录！')
        return
      }

      Dialog.confirm({
        // title: '标题',
        message: '确认删除吗？'
      }).then(() => {
        this.loading = true
        const guid = createGguid()

        deleteWorkFlowList({
          guid,
          autoidlist: autoidlist.join(','),
          formautoid: this.formautoid,
          username: this.userInfo.username,
          formautoid: this.formautoid,
          mkeyname: this.$route.query.mkeyname,
          mtablename: this.$route.query.mtablename,
          mviewname: this.$route.query.mviewname
        })
          .then(res => {
            Toast('删除成功')
            this.initData()
          })
          .finally(() => {
            this.loading = false
          })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.wfClass {
  height: 100vh;
  overflow: hidden;

  .van-nav-bar {
    background: #2b8df0;
    height: 92px;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }

    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }

    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }

  .van-icon {
    padding: 0 0.21333rem;
    color: #323233;
    font-size: 0.37333rem;
    line-height: 0.90667rem;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
  }

  .search {
    display: flex;
    align-items: center;

    .van-search {
      flex: 1;
    }

    .gettype {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #333333;
      font-size: 26px;

      span {
        background: #ddd;
        padding: 10px 0 10px 20px;
        border-radius: 20px;
      }
    }
  }

  .applyCollapse {
    width: 100%;
    background: #f2f2f2;
    &.showcheck{
      height: calc(100vh - 6.3rem);
    }
    padding: 10px;
    overflow-y: scroll;
    height: calc(100vh - 4.5rem);
    &.homeAppClassIs1{
      height: calc(100vh - 5.56667rem);
      &.showcheck{
        height: calc(100vh - 7.36667rem);
      }
    }
    .van-collapse {
      background: #fff;
      border-radius: 20px;
      padding: 10px;
    }

    /deep/.van-cell {
      background: #fff;
      border-radius: 20px;
      padding: 10px;
      margin-bottom: 10px;

      .beforcell {
        display: flex;

        .ico {
          width: 8px;
          height: 22px;
          background: #bd50d3;
          border-radius: 2px;
          margin: 10px;
        }
      }

      .van-cell__title {
        flex: 2;
        text-align: left;
      }

      .st {
        text-align: center;
        color: rgb(235, 144, 91);
        background: rgb(245, 239, 193);
        border-radius: 30px;
        padding: 10px 20px;
      }

      .st.ischeck {
        color: #00b65b;
        background: #c1f5ea;
      }
      .st.isdtj{
        
      }
    }
  }

  .footer_btn {
    width: 100%;
    padding: 0.26667rem 0;
    position: fixed;
    bottom: 1.8rem;
    left: 0;
    z-index: 100;
    display: flex;
    background-color: #fff;
    align-items: flex-end;
    justify-content: space-between;
    padding-left: 0.2rem;
    ::v-deep .van-checkbox__icon {
      margin-left: 0.13333rem;
    }

    ::v-deep .van-checkbox__label {
      font-size: 0.48rem;
      font-family: Source Han Sans CN;
      font-weight: 500;
    }

    .van-button {
     
      margin-right: 0.2rem;
      border-radius: 9px;
      width: 3rem;

      .van-button__text {
        font-size: 0.4rem;
        font-family: Source Han Sans CN;
        font-weight: 500;
      }
    }
  }
}
.documentDjcxBox{
  
}
</style>
